import * as React from "react"
import VerifyEmail from "@components/Auth/VerifyEmail/VerifyEmail"
import Seo from "@components/seo"

const VerifyEmailPage = ({ location }) => (
  <>
    <Seo title="Verify Email" />
    <VerifyEmail location={location} />
  </>
)

export default VerifyEmailPage
